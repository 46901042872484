/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * */
 :root {
  --dark-background-color: #121212;
  --dark-surface-color: #121212;
  --dark-theme-overlay-01dp: rgba(255,255,255,0.05);
  --dark-theme-overlay-02dp: rgba(255,255,255,0.07);
  --dark-theme-overlay-03dp: rgba(255,255,255,0.08);
  --dark-theme-overlay-04dp: rgba(255,255,255,0.09);
  --dark-theme-overlay-06dp: rgba(255,255,255,0.11);
  --dark-theme-overlay-08dp: rgba(255,255,255,0.12);
  --dark-theme-overlay-12dp: rgba(255,255,255,0.14);
  --dark-theme-overlay-16dp: rgba(255,255,255,0.15);
  --dark-theme-overlay-24dp: rgba(255,255,255,0.16);
  --dark-theme-on-background: white;
  --dark-theme-on-surface: white;
  --dark-theme-surface-hovered: rgba(255,255,255,0.04);
  --dark-theme-surface-focused: rgba(255,255,255,0.12);
  --dark-disabled-label-color: rgba(255,255,255,0.38);
  --dark-disabled-container-color: rgba(255,255,255,0.12);
  --dark-high-emphasis-text-color: rgba(255,255,255,0.87);
  --dark-medium-emphasis-text-color: rgba(255,255,255,0.60);
  /* --dark-primary-color: #bb86fc;  */
  --dark-primary-color: #12BCFA;
  --dark-primary-color-bg: #12BCFA1F;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--dark-background-color);
  font-family: 'Roboto', sans-serif;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--dark-background-color);
}

#app {
  height: 100%;
  background-color: var(--dark-background-color);
}

#root {
  height: 100%;
}

/* Common style for top and bottom toolbars. */
.toolbar {
  position: fixed;
  left: 50%;
  width: 450px;
  height: auto;
  margin: 0px 0 0 -233px;
  padding: 8px;
  border-radius: 10px;
  z-index: 100;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
}

@media all and (max-width: 768px) {
  .toolbar {
      left: 0;
      width: 100%;
      border-radius: 0px;
      margin: 0;
    }
}

.navbar {
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--dark-medium-emphasis-text-color);
  flex-flow: row wrap;
  touch-action: none;
}

.navbar > div {
  flex-grow: 1;
  text-align: center;
  font-size: 0.75em;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
       -moz-user-select: none; /* Firefox */
            user-select: none;
                 cursor: default;
}

#timecontrol {
  bottom: 0;
  color: var(--dark-theme-on-surface);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playbar {
  width: 100%;
  height: auto;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row;
  padding: 8px;
  touch-action: none;
}

.playbar > div {
  flex-grow: 1;
  text-align: center;
  font-size: 2em;
}

#currentMapTime {
  font-size: 1.4em;
  color: var(--dark-theme-on-surface);
}

#currentMapDate {
  font-size: 0.6em;
  color: var(--dark-theme-on-surface);
}